import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datetime from '@/components/core/DatePicker';
// Local Deps
import { CFSwitch } from '../../../basic/Toggle/Toggle';
import Constants from '../../../../Constants';
import { Box, Button, HStack, VStack, Flex } from '@chakra-ui/react';
import { Checkbox } from '@/components/ui/checkbox';

const propTypes = {
  invoicingOffice: PropTypes.bool,
  isNotInPreviousReports: PropTypes.bool,
  isSearching: PropTypes.bool,
  onChangeFilter: PropTypes.func,
  onSearch: PropTypes.func,
  readyForPaymentEndDate: PropTypes.string,
  readyForPaymentStartDate: PropTypes.string,
};

const DrawDownFilters = ({
  invoicingOffice,
  isNotInPreviousReports,
  isSearching,
  onChangeFilter,
  onSearch,
  readyForPaymentEndDate,
  readyForPaymentStartDate,
}) => {
  return (
    <VStack gap="30px" align="stretch">
      <Flex gap="63px">
        <Box>
          <label style={{marginBottom:"10px", display:"block"}}>Show PO(s) with tags:</label>
          <Checkbox
            colorScheme="actionSecondary"
            checked={isNotInPreviousReports}
            onChange={e => onChangeFilter('isNotInPreviousReports', e.target.checked)}
          >
            Not in any previous drawdown reports
          </Checkbox>
        </Box>
        <Box>
          <label style={{marginBottom:"10px", display:"inline-block"}}>Payment was requested within this date range:</label>
          <HStack>
            <Datetime
              id="ready-for-payment-start-date"
              inputProps={{
                placeholder: 'Select date',
                className: 'form-control',
              }}
              closeOnSelect
              value={readyForPaymentStartDate && moment(readyForPaymentStartDate, 'YYYY-MM-DD')}
              // renderInput={props => this.renderInput(props)}
              onChange={e => {
                // eslint-disable-next-line no-underscore-dangle
                if (e._isAMomentObject) {
                  onChangeFilter('readyForPaymentStartDate', e.format('YYYY-MM-DD'));
                } else {
                  onChangeFilter('readyForPaymentStartDate', moment(e && e.target ? e.target.value : ''));
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
            <span> to </span>

            <Datetime
              id="ready-for-payment-end-date"
              inputProps={{
                placeholder: 'Select date',
                className: 'form-control',
              }}
              closeOnSelect
              value={readyForPaymentEndDate && moment(readyForPaymentEndDate, 'YYYY-MM-DD')}
              // renderInput={props => this.renderInput(props)}
              onChange={e => {
                // eslint-disable-next-line no-underscore-dangle
                if (e._isAMomentObject) {
                  onChangeFilter('readyForPaymentEndDate', e.format('YYYY-MM-DD'));
                } else {
                  onChangeFilter('readyForPaymentEndDate', moment(e && e.target ? e.target.value : ''));
                }
              }}
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
            />
          </HStack>
        </Box>
        <Box>
          <label style={{marginBottom:"10px", display:"inline-block"}}>And for this invoicing office:</label>
          <CFSwitch
            onClick={value =>
              onChangeFilter(
                'invoicingOffice',
                value ? Constants.BUSINESS_OFFICES.SYDNEY.value : Constants.BUSINESS_OFFICES.CHICAGO.value
              )
            }
            offText={Constants.BUSINESS_OFFICES.CHICAGO.value}
            onText={Constants.BUSINESS_OFFICES.SYDNEY.value}
            isActive={invoicingOffice === Constants.BUSINESS_OFFICES.SYDNEY.value}
          />
        </Box>
        <Button
          colorScheme="actionSecondary"
          width="141px"
          height="43px"
          fontSize="16px"
          disabled={isSearching}
          onClick={onSearch}
          alignSelf="flex-end"
          marginLeft="auto !important"
        >
          Search
        </Button>
      </Flex>
    </VStack>
  );
};

DrawDownFilters.propTypes = propTypes;

export default DrawDownFilters;
